import config_testnet from "./config_testnet";
import config_localnet from "./config_localnet";

export let config = {
  app_name: "waxblock.io",
  network: "wax",
  network_type: "mainnet",
  chain_id: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  token_symbol: "WAX",
  token_precision: 8,
  endpoints: {
    default: "https://api.wax.alohaeos.com",
    sign: "https://wax.greymass.com",
    state: {
      "https://api-wax.eosauthority.com": 100,
      "https://wax.eosphere.io": 80,
      "https://api.wax.alohaeos.com": 100,
      "https://wax.dapplica.io": 100,         
      "https://api.waxsweden.org": 100,   
    },
    block: {
      "https://wax.greymass.com": 100, //temp
      //"https://wax.eosdublin.io": 100,
      "https://api-wax.eosauthority.com": 100,
      "https://api-wax.eosarabia.net": 90,
      "https://api.wax.alohaeos.com": 100,
      "https://wax.dapplica.io": 100,    
      "https://history-wax-mainnet.wecan.dev": 100,
      "https://api.waxsweden.org": 100,   
    },
    transaction: {
      "https://wax.greymass.com": 100, 
      // "https://wax.eosphere.io": 100,
      // "https://api-wax.eosauthority.com": 100,
      // "https://api-wax.eosarabia.net": 70,
      // "https://api.wax.alohaeos.com": 100,
      // "https://wax.dapplica.io": 100,    
      // "https://history-wax-mainnet.wecan.dev": 100,
      // "https://api.waxsweden.org": 100,   
    },
    history: {
      "https://wax.greymass.com": 100, 
      // "https://wax.eosphere.io": 100,
      // "https://api-wax.eosauthority.com": 100,
      // "https://api.wax.alohaeos.com": 100,
      // "https://wax.dapplica.io": 100,    
      // "https://history-wax-mainnet.wecan.dev": 100,
      // "https://api.waxsweden.org": 100,   
    },
    transactionV2: {
      "https://wax.eosphere.io": 100,
      "https://api-wax.eosauthority.com": 100,
      "https://api-wax.eosarabia.net": 70,
      "https://api.wax.alohaeos.com": 100,
      "https://wax.dapplica.io": 100,    
      "https://history-wax-mainnet.wecan.dev": 100,
      "https://api.waxsweden.org": 100, 
    },
    historyV2: {
      "https://wax.eosphere.io": 100,
      "https://api-wax.eosauthority.com": 100,
      "https://api.wax.alohaeos.com": 100,
      "https://wax.dapplica.io": 100,    
      "https://history-wax-mainnet.wecan.dev": 100,
      "https://api.waxsweden.org": 100, 
    },
    lightapi: {
      "https://lightapi.eosamsterdam.net": 100,
    },
    nfts: {
      simpleassets: "https://wax.api.simpleassets.io",
      atomicassets: "https://wax.api.atomicassets.io",
    },
  },
  imagekit: {
    default_url_endpoint: "https://ik.imagekit.io/waxblock",
  },
  socket: {
    url: process.env.REACT_APP_SOCKET_URL || "http://127.0.0.1:8080",
    path: "/socket",
  },
  api: {
    url: process.env.REACT_APP_API_URL || "http://127.0.0.1:8080",
    path: "/api/v1",
  },
  anchorLink: {
    app_name: "waxblock.io",
  },
};

//Switch config based network
if (
  process.env.REACT_APP_NETWORK_TYPE === "testnet" ||
  process.env.REACT_APP_CF_NETWORK_TYPE === "testnet"
) {
  config = { ...config, ...config_testnet };
}else if (
  process.env.REACT_APP_NETWORK_TYPE === "localnet" ||
  process.env.REACT_APP_CF_NETWORK_TYPE === "localnet"
) {
  config = { ...config, ...config_localnet };
}

export default config;
