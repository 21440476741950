import { useContext, useEffect, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { createBrowserHistory } from "history";
import { WalletContext } from "../../../app/contexts/wallet-context";

const ContractActions = ({ accountAbi }) => {
  const history = createBrowserHistory();
  const [searchParams] = useSearchParams();
  const wallet = useContext(WalletContext);

  const [activeAction, setActiveAction] = useState(null);
  const [actionStruct, setActionStruct] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (searchParams.get("action")) {
      const action = accountAbi.abi.actions.find(
        (action) => action.name === searchParams.get("action")
      );
      setActiveAction(action);

      const struct = accountAbi.abi.structs.find(
        (struct) => struct.name === action?.type
      );
      setActionStruct(struct);
    } else if (accountAbi.abi.actions[0]) {
      // const action = accountAbi.abi.actions.find(
      //   (action) => action.name === accountAbi.abi.actions[0]?.name
      // );
      // setActiveAction(action);
      // const struct = accountAbi.abi.structs.find(
      //   (struct) => struct.name === action?.type
      // );
      // setActionStruct(struct);
    }
  }, []);

  useEffect(() => {
    if (activeAction) {
      history.push({
        search: `?${createSearchParams({
          action: activeAction?.name,
        }).toString()}`,
        hash: "contract-actions",
      });
    }

    var data = {};
    actionStruct &&
      actionStruct.fields &&
      actionStruct.fields.forEach((field, index) => {
        data[field.name] = "";
      });
    setFormData(data);
    window.$(".form_sec input[type=text]").val("");
  }, [actionStruct]);

  const pushTransaction = () => {
    var data = {};

    actionStruct.fields.forEach((field, index) => {
      var value;
      try {
        value = JSON.parse(formData[field.name]);
      } catch (e) {
        value = formData[field.name];
      }
      data[field.name] = typeof value !== "undefined" ? value : "";
    });

    var walletTransaction = {};
    walletTransaction.type = "contractAction";

    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: accountAbi.account_name,
      name: activeAction.name,
      data: data,
    });

    wallet.submitTransaction(walletTransaction);
  };

  return (
    <>
      <h3>Select Action</h3>
      {accountAbi.abi.actions.map((action, index) => (
        <a
          className={`more_btn ${
            activeAction?.name === action.name ? "active" : ""
          }`}
          style={{
            marginBottom: "10px",
            padding: "10px",
            width: "auto",
            cursor: "pointer",
            marginRight: "10px",
            fontSize: "12px",
            lineHeight: "14px",
            background:
              activeAction?.name === action.name ? "#7D70F6" : "#404047",
            textTransform: "none",
          }}
          key={index}
          onClick={() => {
            setActiveAction(action);
            const struct = accountAbi.abi.structs.find(
              (struct) => struct.name === action.type
            );
            setActionStruct(struct);
          }}
        >
          {action.name}
        </a>
      ))}
      {actionStruct && actionStruct.fields ? (
        <div className="mid_sec">
          <div className="form_sec" style={{ width: "100%" }}>
            <div className="form_box  active">
              <h3>Enter Data</h3>
              <form>
                {actionStruct.fields.map((field, index) => (
                  <div className="field_row" key={index}>
                    <div className="field_col">
                      <label>{field.name}</label>
                      <input
                        type="text"
                        placeholder={`${field.type}`}
                        value={formData[field.name]}
                        onChange={(event) => {
                          formData[field.name] = event.target.value;
                          setFormData({ ...formData });
                          //field.value = event.target.value;
                          //actionStruct.fields[index].value = event.target.value;
                          //setActionStruct(actionStruct);
                          // setActionStruct((actionStruct) => {
                          //   actionStruct.fields[index].value =
                          //     event.target.value;
                          //   return actionStruct;
                          // });
                        }}
                      />
                    </div>
                  </div>
                ))}
                <div className="field_row1">
                  <input
                    type="button"
                    value="Submit Transaction"
                    onClick={pushTransaction}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ContractActions;
