import { getType } from "eosjs/dist/eosjs-serialize";
import ReactPaginate from "react-paginate";
import Skeleton from "react-loading-skeleton";
import { useQuery, useInfiniteQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import BlockchainApiManager from "../../../app/services/blockchainApiManager";
import moment from "moment";
import PageTransition from "../../../app/animations/page-transition";
import { getShortTrxId } from "../../../app/utilities/transaction";
import React from "react";
import DisplayActionAccountName from "../../../app/components/blockchain/action/display-action-account-name";
import DisplayActionData from "../../../app/components/blockchain/action/display-action-data";
import { momentformat } from "../../../app/utilities/date";
import { useEffect } from "react";
import { useState } from "react";
import usePrevious from "../../../app/hooks/usePrevious";
import config from "../../../app/config";

const Transactions = ({ balances }) => {
  let params = useParams();
  const prevParams = usePrevious(params);

  const initialFilters = {
    hideSmallTransfers: false,
    hideSpam: true,
    date: { start: null, end: null },
    actions: {
      receive_token: false,
      send_token: false,
      contract: false,
      account: false,
      resource: false,
      producer: false,
      vote: false,
    },
    token: null,
    token_search: "",
    token_balance: null,
    contract: { code: null, action: null },
    search: "",
  };

  const initialSettings = {
    smallTransfersThreshold: 0.001,
    spamActions: [],
    mode: "v1",
    datesInUtc: false,
  };

  const [filters, setFilters] = useState(initialFilters);

  const [settings, setSettings] = useState(initialSettings);

  //const [currentItems, setCurrentItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const itemsPerPage = 100;
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  //const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if (prevParams && prevParams.account !== params.account) {
      setTotalItems(0);
      setPage(0);
      setPageCount(0);
    }
  }, [params, prevParams]);

  let {
    isLoading,
    refetch,
    isRefetching,
    data: actions,
  } = useQuery(
    [
      `actions`,
      params.account,
      settings.mode,
      filters.actions,
      filters.contract,
      filters.date,
      filters.token,
      //page
    ],
    async ({ queryKey }) => {
      let response;

      let pageParam = settings.mode === "v2" ? 0 : -1;

      if (
        totalItems &&
        (!prevParams || prevParams.account === params.account)
      ) {
        if (settings.mode === "v2") {
          pageParam = page * itemsPerPage;
        } else {
          pageParam = totalItems - page * itemsPerPage;
        }
      }
      // if (settings.mode === "v2") {
      //   if (currentItems && currentItems[0]) {
      //     pageParam = 99;
      //   }
      // } else {

      //  // if (currentItems && currentItems[0]) {
      //   //  pageParam = currentItems[0].account_action_seq;
      //  // }
      // }

      if (settings.mode === "v2") {
        var queryParams = {};

        queryParams.limit = itemsPerPage;
        queryParams.skip = pageParam;

        if (filters.date.start) {
          queryParams.after = filters.date.start;
        }
        if (filters.date.end) {
          queryParams.before = filters.date.end;
        }

        queryParams.filter = [];
        if (filters.actions.receive_token || filters.actions.send_token) {
          queryParams.filter.push("*:transfer");
        }
        if (!(filters.actions.receive_token && filters.actions.send_token)) {
          if (filters.actions.receive_token) {
            queryParams["transfer.to"] = params.account;
          }
          if (filters.actions.send_token) {
            queryParams["transfer.from"] = params.account;
          }
        }

        if (filters.actions.contract) {
          queryParams.filter.push("eosio:setcode,eosio:setabi");
        }
        if (filters.actions.account) {
          queryParams.filter.push(
            "eosio:newaccount,eosio.msig:approve,eosio.msig:propose,eosio.msig:cancel,eosio.msig:exec,eosio:linkauth,eosio:unlinkauth,eosio:updateauth,eosio:deleteauth"
          );
        }
        if (filters.actions.resource) {
          queryParams.filter.push(
            "eosio:delegatebw,eosio:undelegatebw,eosio:buyrambytes,eosio:sellrambytes,eosio:sellram,eosio:buyram"
          );
        }
        if (filters.actions.producer) {
          queryParams.filter.push(
            "eosio:claimrewards,eosio:regproducer,eosio:unregprod"
          );
        }
        if (filters.actions.vote) {
          queryParams.filter.push("eosio:voteproducer,eosio:vote,eosio:unvote");
        }

        if (filters.token && filters.token !== "all") {
          queryParams.filter.push(`${filters.token}:*`);
        }

        if (filters.contract.code || filters.contract.action) {
          queryParams.filter.push(
            `${filters.contract.code || "*"}:${filters.contract.action || "*"}`
          );
        }

        if (queryParams.filter.length > 0) {
          queryParams.filter = queryParams.filter.join(",");
        } else {
          delete queryParams.filter;
        }

        response = await BlockchainApiManager.getV2Actions(
          params.account,
          queryParams
        );
      } else {
        response = await BlockchainApiManager.getActions({
          account_name: params.account,
          pos: pageParam,
          //offset: pageParam === -1 ? -100 : -99,
          offset: -itemsPerPage,
        });

        //FIX: Testnet had issue in data sort order and action seq
        if (config.network_type === "testnet" && response && response.actions) {
          //Sort data
          response.actions.sort(
            (a, b) => b.account_action_seq - a.account_action_seq
          );
          if (!totalItems) {
            let lastAction = response.actions.slice(1).pop();
            let totalCount = lastAction ? lastAction.account_action_seq + 1 : 0;
            setTotalItems(totalCount);
            setPageCount(Math.ceil(totalCount / itemsPerPage));
          }
        }
      }

      if (response && response.actions) {
        const response2 = { ...response, actions: [] };
        response.actions.forEach(function (action) {
          let action2;
          if (settings.mode === "v2") {
            action.act.hex_data = JSON.stringify(action.act.data);
            action2 = response2.actions.find(
              (action2) =>
                action2.trx_id === action.trx_id &&
                action2.act.hex_data === action.act.hex_data
            );
          } else {
            action2 = response2.actions.find(
              (action2) =>
                action2.action_trace.trx_id === action.action_trace.trx_id &&
                action2.action_trace.act.hex_data ===
                  action.action_trace.act.hex_data
            );
          }
          if (!action2) {
            response2.actions.push(action);
          }
        });

        return response2;
      } else {
        return response;
      }
    },
    {
      //   getNextPageParam: (lastPage) => {
      //     alert(lastPage);
      //     if (settings.mode === "v2") {
      //       if (lastPage && lastPage.actions[0]) {
      //         return 99;
      //       }
      //     } else {
      //       if (lastPage && lastPage.actions[0]) {
      //         //return lastPage.actions[0].account_action_seq - 1;
      //         return lastPage.actions[0].account_action_seq;
      //       }
      //     }
      //     return false;
      //   },
    }
  );

  //console.log(actions,currentItems)
  useEffect(() => {
    if (!actions) return;
    // Fetch items from another resources.
    //const endOffset = itemOffset + itemsPerPage;
    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    //setCurrentItems(proxies.slice(itemOffset, endOffset));
    //setCurrentItems(actions.actions);
    //console.log(actions);
    if (!totalItems) {
      let totalCount;
      if (settings.mode === "v2") {
        totalCount = actions.total.value;
      } else {
        let lastAction = actions.actions.slice(-1).pop();
        totalCount = lastAction ? lastAction.account_action_seq + 1 : 0;
      }

      setTotalItems(totalCount);
      setPageCount(Math.ceil(totalCount / itemsPerPage));
    }
  }, [itemsPerPage, actions]);

  const handlePageClick = (event) => {
    //setCurrentItems([]);
    setPage(event.selected);

    //const newOffset = ((page - 1) * itemsPerPage) % proxies.length;

    //setItemOffset(newOffset);
  };
  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    window.$('input[name="datetimes"]').daterangepicker({
      autoUpdateInput: false,
      locale: {
        cancelLabel: "Clear",
      },
      maxDate: moment().utc().endOf("day"),
    });
    window
      .$('input[name="datetimes"]')
      .on("apply.daterangepicker", function (ev, picker) {
        window
          .$(this)
          .val(
            picker.startDate.format("ddd, MMM D, YYYY hh:mm A") +
              " - " +
              picker.endDate.format("ddd, MMM D, YYYY hh:mm A")
          );
        window.$(this).siblings(".reset_val").show();

        setFilters((filters) => ({
          ...filters,
          date: {
            ...filters.date,
            start: picker.startDate.format("YYYY-MM-DDTHH:mm:ss.000") + "Z",
            end: picker.endDate.format("YYYY-MM-DDTHH:mm:ss.000") + "Z",
          },
        }));
      });

    window
      .$('input[name="datetimes"]')
      .on("cancel.daterangepicker", function (ev, picker) {
        window.$(this).val("");
        window.$(this).siblings(".reset_val").hide();
        setFilters((filters) => ({
          ...filters,
          date: {
            ...filters.date,
            start: null,
            end: null,
          },
        }));
      });

    window.$("button.reset_val").on("click", function () {
      window.$('input[name="datetimes"]').val("");
      window.$('input[name="datetimes"]').siblings(".reset_val").hide();
      setFilters((filters) => ({
        ...filters,
        date: {
          ...filters.date,
          start: null,
          end: null,
        },
      }));
    });
  }, []);

  const sortAction = (rowA, rowB) => {
    if (settings.mode === "v2") {
      return moment(rowB.block_time).unix() - moment(rowA.block_time).unix();
    } else {
      return (
        moment(rowB.action_trace.block_time).unix() -
        moment(rowA.action_trace.block_time).unix()
      );
    }
  };

  const filterAction = (action) => {
    //Search
    if (filters.search) {
      var text = "";
      var items = [];
      //var memo = '';
      if (settings.mode === "v2") {
        items = [action.trx_id, action.act.account, action.act.name];
        action.act.data &&
          Object.keys(action.act.data).forEach(function (key) {
            items.push([key, action.act.data[key]].join(": "));
          });
        text = items.join(" ");
        //memo = (action.act.data && action.act.data.memo) ? action.act.data.memo : '';
      } else if (settings.mode === "v1") {
        //memo = (action.action_trace.act.data && action.action_trace.act.data.memo) ? action.action_trace.act.data.memo : '';
        items = [
          action.action_trace.trx_id,
          action.action_trace.act.account,
          action.action_trace.act.name,
        ];
        action.action_trace.act.data &&
          Object.keys(action.action_trace.act.data).forEach(function (key) {
            items.push([key, action.action_trace.act.data[key]].join(": "));
          });
        text = items.join(" ");
      }

      if (text.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
        return false;
      }
    }
    //Small transfers check
    if (filters.hideSmallTransfers) {
      var amount = null;
      var from_account = null;
      var to_account = null;
      if (settings.mode === "v2") {
        amount =
          action.act.data && action.act.data.amount
            ? action.act.data.amount
            : null;
        from_account =
          action.act.data && action.act.data.from ? action.act.data.from : null;
        to_account =
          action.act.data && action.act.data.to ? action.act.data.to : null;
      } else if (settings.mode === "v1") {
        amount =
          action.action_trace.act.data && action.action_trace.act.data.quantity
            ? parseFloat(action.action_trace.act.data.quantity)
            : null;
        from_account =
          action.action_trace.act.data && action.action_trace.act.data.from
            ? action.action_trace.act.data.from
            : null;
        to_account =
          action.action_trace.act.data && action.action_trace.act.data.to
            ? action.action_trace.act.data.to
            : null;
      }

      if (
        amount &&
        amount < settings.smallTransfersThreshold &&
        to_account === params.account
      ) {
        return false;
      }
    }

    //Spam check
    if (filters.hideSpam) {
      var contractActionName = null;
      var contract = null;
      var actionName = null;

      var actor = null;
      var data = null;
      if (settings.mode === "v2") {
        contractActionName = [action.act.account, "::", action.act.name].join(
          ""
        );
        contract = action.act.account;
        actionName = action.act.name;
        actor = action.act.authorization[0]
          ? action.act.authorization[0].actor
          : null;
        data = action.act.data;
      } else if (settings.mode === "v1") {
        contractActionName = [
          action.action_trace.act.account,
          "::",
          action.action_trace.act.name,
        ].join("");
        contract = action.action_trace.act.account;
        actionName = action.action_trace.act.name;

        actor = action.action_trace.act.authorization[0]
          ? action.action_trace.act.authorization[0].actor
          : null;
        data = action.action_trace.act.data;
      }

      if (
        contractActionName &&
        settings.spamActions.includes(contractActionName)
      ) {
        return false;
      }

      if (actor && actor === params.account) {
        return true;
      } else if (
        actionName === "transfer" &&
        data &&
        data.to === params.account &&
        actor &&
        actor === data.from
      ) {
        return true;
      } else if (contract === params.account) {
        return true;
      } else if (
        contract.startsWith("eosio") ||
        contract.startsWith("eosio.") ||
        contract.startsWith("atomicassets")
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    applyFilters();
  }, [filters.actions, filters.contract, filters.date, filters.token]);

  const applyFilters = () => {
    let mode = settings.mode;

    //Check and switch mode
    if (
      Object.values(filters.actions).includes(true) ||
      filters.contract.code ||
      filters.contract.action ||
      filters.date.start ||
      filters.date.end ||
      filters.token
    ) {
      mode = "v2";
    } else {
      mode = "v1";
    }

    //Override for localnet
    mode =
      config.network_type === "localnet" &&
      Object.keys(config.endpoints.historyV2).length
        ? "v2"
        : mode;

    setSettings({
      ...settings,
      mode: mode,
    });
    //setCurrentItems([]);
    setTotalItems(0);
    setPage(0);
    setPageCount(0);
  };

  const resetFilters = (e) => {
    e.preventDefault();
    setFilters(initialFilters);
    setSettings(initialSettings);
    window.$("input[name=contract_code]").val("");
    window.$("input[name=contract_action]").val("");
    window.$("button.reset_val").click();
  };

  const refreshActions = (e) => {
    e.preventDefault();
    refetch();
  };

  return (
    <>
      <div className="filter_sec">
        <ul className="extra_btns">
          <li>
            <a
              data-tip="Reset Filters"
              href="#"
              className="reset_btn"
              onClick={resetFilters}
            >
              <i className="fa fa-times" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a
              data-tip="Refresh Actions"
              href="#"
              className="refresh_btn"
              onClick={refreshActions}
            >
              <i className="fa fa-refresh" aria-hidden="true" />
            </a>
          </li>
        </ul>
        <div className="filter_row">
          <div className="filter_box">
            <h4>
              Category Action Filter:{" "}
              <span data-tip="Filters entire history for category type.">
                <i className="fa fa-info-circle" aria-hidden="true" />
              </span>
            </h4>
            <div className="cat_btns">
              <input
                type="button"
                defaultValue="Receive Token"
                className={`${filters.actions.receive_token ? "active" : ""}`}
                onClick={() => {
                  setFilters((filters) => ({
                    ...filters,
                    actions: {
                      ...filters.actions,
                      receive_token: !filters.actions.receive_token,
                    },
                  }));
                }}
              />
              <input
                type="button"
                defaultValue="Send Token"
                className={`${filters.actions.send_token ? "active" : ""}`}
                onClick={() => {
                  setFilters((filters) => ({
                    ...filters,
                    actions: {
                      ...filters.actions,
                      send_token: !filters.actions.send_token,
                    },
                  }));
                }}
              />
              <input
                type="button"
                defaultValue="Contract"
                className={`${filters.actions.contract ? "active" : ""}`}
                onClick={() => {
                  setFilters((filters) => ({
                    ...filters,
                    actions: {
                      ...filters.actions,
                      contract: !filters.actions.contract,
                    },
                  }));
                }}
              />
              <input
                type="button"
                defaultValue="Account"
                className={`${filters.actions.account ? "active" : ""}`}
                onClick={() => {
                  setFilters((filters) => ({
                    ...filters,
                    actions: {
                      ...filters.actions,
                      account: !filters.actions.account,
                    },
                  }));
                }}
              />
              <input
                type="button"
                defaultValue="RAM / CPU / NET"
                className={`${filters.actions.resource ? "active" : ""}`}
                onClick={() => {
                  setFilters((filters) => ({
                    ...filters,
                    actions: {
                      ...filters.actions,
                      resource: !filters.actions.resource,
                    },
                  }));
                }}
              />
              <input
                type="button"
                defaultValue="Producer"
                className={`${filters.actions.producer ? "active" : ""}`}
                onClick={() => {
                  setFilters((filters) => ({
                    ...filters,
                    actions: {
                      ...filters.actions,
                      producer: !filters.actions.producer,
                    },
                  }));
                }}
              />
              <input
                type="button"
                defaultValue="Vote"
                className={`${filters.actions.vote ? "active" : ""}`}
                onClick={() => {
                  setFilters((filters) => ({
                    ...filters,
                    actions: {
                      ...filters.actions,
                      vote: !filters.actions.vote,
                    },
                  }));
                }}
              />
            </div>
          </div>
          <div className="filter_box">
            <h4>
              Contract/Action Filter:{" "}
              <span data-tip="Filters entire history for contract and/or action.">
                <i className="fa fa-info-circle" aria-hidden="true" />
              </span>
            </h4>
            <div className="cont_filter">
              <input
                type="text"
                name="contract_code"
                placeholder="Contract name"
                //value={filters.contract.code}
                onChange={(el) => {
                  window
                    .$("input[name=contract_code]")
                    .val(el.target.value.toLowerCase());
                  // setFilters({
                  //   ...filters,
                  //   contract: { ...filters.contract, code: el.target.value },
                  // });
                }}
              />
              <input
                type="text"
                name="contract_action"
                placeholder="Action name"
                //value={filters.contract.action}
                onChange={(el) => {
                  window
                    .$("input[name=contract_action]")
                    .val(el.target.value.toLowerCase());
                  //   setFilters({
                  //     ...filters,
                  //     contract: { ...filters.contract, action: el.target.value },
                  //   });
                }}
              />
              <input
                type="button"
                defaultValue="Filter"
                onClick={() => {
                  //applyFilters();
                  setFilters((filters) => ({
                    ...filters,
                    contract: {
                      ...filters.contract,
                      code: window.$("input[name=contract_code]").val(),
                      action: window.$("input[name=contract_action]").val(),
                    },
                  }));
                }}
              />
            </div>
          </div>
          <div className="filter_box">
            <h4>
              Date Filter:{" "}
              <span data-tip="Filters action by date range.">
                <i className="fa fa-info-circle" aria-hidden="true" />
              </span>
            </h4>
            <div className="date_filter">
              <div className="data-container">
                <input
                  type="text"
                  placeholder="Select date & time"
                  name="datetimes"
                  id="datetimes"
                  autoComplete="off"
                />
                <button className="reset_val" type="button">
                  <i className="fa fa-times" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className="filter_box">
            <h4>
              Token Filter:{" "}
              <span data-tip="Filters entire history for token actions.">
                <i className="fa fa-info-circle" aria-hidden="true" />
              </span>
            </h4>
            <div className="token_filter">
              <button type="button" className="search_token_btn">
                <i className="fa fa-filter" aria-hidden="true" />
                {!filters.token_balance
                  ? "All Tokens"
                  : `${filters.token_balance.currency} ${filters.token_balance.contract}`}
              </button>
              <div className="search_box">
                <div className="autocomplete" style={{ width: "100%" }}>
                  <input
                    id="tokenInput"
                    type="text"
                    name="id"
                    placeholder
                    autoComplete="off"
                    value={filters.token_search}
                    onChange={(e) => {
                      setFilters((filters) => ({
                        ...filters,
                        token_search: e.target.value.toLowerCase(),
                      }));
                    }}
                  />
                  <div className="autocomplete-items">
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setFilters((filters) => ({
                          ...filters,
                          token: "all",
                          token_balance: null,
                        }));
                      }}
                    >
                      <a href="#">All Tokens</a>
                    </div>
                    {balances &&
                      balances.balances &&
                      [...balances.balances]
                        .sort((a, b) => (a.currency > b.currency ? 1 : -1))
                        //.filter((balance) => balance.amount > 0)
                        .filter((balance) => {
                          if (
                            [" ", balance.currency, balance.contract]
                              .join(" ")
                              .toLowerCase()
                              .indexOf(filters.token_search.toLowerCase()) ===
                            -1
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .map((balance, index) => (
                          <div
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              setFilters((filters) => ({
                                ...filters,
                                token: balance.contract,
                                token_balance: balance,
                              }));
                            }}
                          >
                            <a href="#">
                              {balance.currency} {balance.contract}
                            </a>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filter_box">
            <h4>
              Action Settings:{" "}
              <span
                data-tip={`Hide small balances: Less than ${settings.smallTransfersThreshold}.`}
              >
                <i className="fa fa-info-circle" aria-hidden="true" />
              </span>
            </h4>
            <div className="action_settings">
              <div className="checkbox">
                <input
                  type="checkbox"
                  className="check_input"
                  id="dates-in-utc"
                  checked={settings.datesInUtc}
                  onChange={(el) => {
                    setSettings({
                      ...settings,
                      datesInUtc: el.target.checked,
                    });
                  }}
                />
                <label className="check_label" htmlFor="dates-in-utc">
                  Dates in UTC
                </label>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  className="check_input"
                  id="hide-small-balances"
                  checked={filters.hideSmallTransfers}
                  onChange={(el) => {
                    setFilters((filters) => ({
                      ...filters,
                      hideSmallTransfers: el.target.checked,
                    }));
                  }}
                />
                <label className="check_label" htmlFor="hide-small-balances">
                  Hide Small Balances
                </label>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  className="check_input"
                  id="hide-spam"
                  checked={filters.hideSpam}
                  onChange={(el) => {
                    setFilters((filters) => ({
                      ...filters,
                      hideSpam: el.target.checked,
                    }));
                  }}
                />
                <label className="check_label" htmlFor="hide-spam">
                  Hide Spam
                </label>
              </div>
            </div>
          </div>
          <div className="filter_box">
            <div className="memo">
              <input
                type="search"
                placeholder="Search ..."
                aria-label="Search"
                value={filters.search}
                onChange={(el) => {
                  setFilters((filters) => ({
                    ...filters,
                    search: el.target.value,
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="table_row">
        <div className="table_row_inner">
          <table>
            <thead>
              <tr>
                <th style={{ width: "15%" }}>TX ID</th>
                <th style={{ width: "15%" }}>Date</th>
                <th style={{ width: "15%" }}>Action</th>
                <th style={{ width: "55%" }}>Data</th>
              </tr>
            </thead>
            <tbody>
              {(!actions || isRefetching) &&
                Array(5)
                  .fill(0)
                  .map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "15%" }}>
                        <Skeleton />
                      </td>
                      <td style={{ width: "15%" }}>
                        <Skeleton />
                      </td>
                      <td style={{ width: "15%" }}>
                        <Skeleton />
                      </td>
                      <td style={{ width: "55%" }}>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}

              {/* {actions &&
                actions.actions.map((action, index) => ( */}
              {actions &&
                actions.actions
                  .filter(filterAction)
                  .sort(sortAction)
                  .map((action, index) => (
                    <tr key={index}>
                      <td style={{ width: "15%" }}>
                        <Link
                          to={`/transaction/${
                            settings.mode === "v2"
                              ? action.trx_id
                              : action.action_trace.trx_id
                          }`}
                        >
                          {getShortTrxId(
                            settings.mode === "v2"
                              ? action.trx_id
                              : action.action_trace.trx_id
                          )}
                        </Link>
                        &nbsp;
                        {momentformat(
                          settings.mode === "v2"
                            ? action.timestamp
                            : action.action_trace.block_time,
                          "YYYY-MM-DD HH:mm:ss",
                          settings.datesInUtc
                        ) >
                        momentformat(
                          moment().subtract(3, "minutes"),
                          "YYYY-MM-DD HH:mm:ss",
                          settings.datesInUtc
                        ) ? (
                          <span
                            className="tooltip"
                            data-tip="This is a reversible transaction since it has not been confirmed by at least 15/21 validators."
                          >
                            <i
                              className="fa fa-clock-o"
                              aria-hidden="true"
                              style={{ color: "#f2711c" }}
                            />
                          </span>
                        ) : null}
                      </td>
                      <td style={{ width: "15%", whiteSpace: "nowrap" }}>
                        {/* {moment(action.action_trace.block_time).format(
                          "MMM DD YYYY, HH:mm:ss"
                        )} */}
                        {momentformat(
                          settings.mode === "v2"
                            ? action.timestamp
                            : action.action_trace.block_time,
                          "MMM DD YYYY, HH:mm:ss",
                          settings.datesInUtc
                        )}
                      </td>
                      <td style={{ width: "15%", whiteSpace: "nowrap" }}>
                        <Link
                          to={`/account/${
                            settings.mode === "v2"
                              ? action.act.account
                              : action.action_trace.act.account
                          }`}
                        >
                          <span className="type">
                            {settings.mode === "v2"
                              ? action.act.account
                              : action.action_trace.act.account}
                          </span>
                        </Link>
                        &nbsp;
                        <span className="type">
                          {settings.mode === "v2"
                            ? action.act.name
                            : action.action_trace.act.name}
                        </span>
                      </td>
                      <td style={{ width: "55%" /*,wordBreak:'break-all'*/ }}>
                        <DisplayActionData
                          action={
                            settings.mode === "v2"
                              ? action.act
                              : action.action_trace.act
                          }
                          account_name={params.account}
                        />
                        {/* <br/>
                          {JSON.stringify(action.action_trace.act.data)} */}
                      </td>
                    </tr>
                  ))}
              {actions &&
              actions.actions.filter(filterAction).sort(sortAction).length ===
                0 ? (
                <tr>
                  <td colSpan={4}>
                    No Actions Found (check filters or load more actions)
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>

      <ReactPaginate
        onPageChange={handlePageClick}
        previousLabel="<"
        nextLabel=">"
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        forcePage={page}
        className={"pagination"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        renderOnZeroPageCount={null}
      />
    </>
  );
};

export default Transactions;
